@import "variables";
//Bootstrap Required
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";

.teaser {
    > a {
        width: 100%;
        height: 100%;
        position: relative;
        text-decoration: none;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        outline: 2px solid $black;

        &::before {
            content: '';
            display: block;
        }
    }

    .teaser-content {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        text-align: center;
        width: 100%;
        height: 100%;

        span {
            transition: all 800ms ease-in-out;
        }

        .big {
            font-size: 5rem;
            line-height: 1;

            @include media-breakpoint-down(lg) {
                font-size: 3.5rem;
            }

            @include media-breakpoint-down(md) {
                font-size: 2.5rem;
            }
        }
    }

    &:hover {
        .teaser-content span {
            transform: scale(1.07);
        }
    }
}

.teaser_bg-color {
    font-size: 3.75rem;
    line-height: 1.167;
    text-transform: uppercase;
    color: white;

    @include media-breakpoint-down(lg) {
        font-size: 2.6rem;
    }

    @include media-breakpoint-down(md) {
        font-size: 1.406rem;

        .star-icon svg {
            width: 29px;
            height: 19px;
        }
    }

    a {
        color: $black;
        font-family: $font-family-sans-serif;
    }
}

.teaser_bg-img {
    background-size: cover;
    font-size: 2.187rem;
    line-height: 1;
    color: $white;

    @include media-breakpoint-down(lg) {
        font-size: 1.9rem;
    }

    @include media-breakpoint-down(md) {
        font-size: 1.64rem;
    }

    a {
        color: $white
    }

    &:hover {
        filter: grayscale(1);
    }
}

.teaser_bottom {
    a {
        align-items: flex-end;

        .teaser-content {
            padding-bottom: 14.58%;
        }
    }
}

.teaser_1_3 a::before {
    padding-bottom: 100%;
}

.teaser_1_2 a::before {
    padding-bottom: 66.66666%;
}

.teaser_1_4 a::before {
    padding-bottom: 133.3333%;
}

@media (min-width: map-get($grid-breakpoints, xs)) and (max-width: map-get($grid-breakpoints, sm)) {
    .teaser-grid .row {
        margin-left: 0;
        margin-right: 0;
    }

    .teaser-grid .container,
    .teaser-grid .row > .col,
    .teaser-grid .row > [class*="col-"] {
        padding-right: 0;
        padding-left: 0;
    }
}

.product-teaser-grid {
    border-bottom: 1px solid $black;
    border-top: 1px solid $black;
}

.product-teaser {
    .teaser-content {
        padding-left: 20%;
        padding-right: 20%;
        padding-top: 10%;
        padding-bottom: 10%;
    }
}

.product-teaser {
    width: 100%;
    height: 100%;
    position: relative;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border: 1px solid $black;

    @include media-breakpoint-down(sm) {
        border-left: none;
        border-right: none;
    }


    &::before {
        content: '';
        display: block;
        padding-bottom: 100%;
    }

    .btn-primary {
        margin-top: 0 !important;
    }
}

.teaser-grid {
    .teaser {
        margin-bottom: 0;
    }
}
