.teaser > a {
  width: 100%;
  height: 100%;
  position: relative;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  outline: 2px solid #000000;
}
.teaser > a::before {
  content: "";
  display: block;
}
.teaser .teaser-content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  width: 100%;
  height: 100%;
}
.teaser .teaser-content span {
  transition: all 800ms ease-in-out;
}
.teaser .teaser-content .big {
  font-size: 5rem;
  line-height: 1;
}
@media (max-width: 1398.98px) {
  .teaser .teaser-content .big {
    font-size: 3.5rem;
  }
}
@media (max-width: 1024.98px) {
  .teaser .teaser-content .big {
    font-size: 2.5rem;
  }
}
.teaser:hover .teaser-content span {
  transform: scale(1.07);
}

.teaser_bg-color {
  font-size: 3.75rem;
  line-height: 1.167;
  text-transform: uppercase;
  color: white;
}
@media (max-width: 1398.98px) {
  .teaser_bg-color {
    font-size: 2.6rem;
  }
}
@media (max-width: 1024.98px) {
  .teaser_bg-color {
    font-size: 1.406rem;
  }
  .teaser_bg-color .star-icon svg {
    width: 29px;
    height: 19px;
  }
}
.teaser_bg-color a {
  color: #000000;
  font-family: "GT America", sans-serif;
}

.teaser_bg-img {
  background-size: cover;
  font-size: 2.187rem;
  line-height: 1;
  color: #ffffff;
}
@media (max-width: 1398.98px) {
  .teaser_bg-img {
    font-size: 1.9rem;
  }
}
@media (max-width: 1024.98px) {
  .teaser_bg-img {
    font-size: 1.64rem;
  }
}
.teaser_bg-img a {
  color: #ffffff;
}
.teaser_bg-img:hover {
  filter: grayscale(1);
}

.teaser_bottom a {
  align-items: flex-end;
}
.teaser_bottom a .teaser-content {
  padding-bottom: 14.58%;
}

.teaser_1_3 a::before {
  padding-bottom: 100%;
}

.teaser_1_2 a::before {
  padding-bottom: 66.66666%;
}

.teaser_1_4 a::before {
  padding-bottom: 133.3333%;
}

@media (min-width: 325px) and (max-width: 576px) {
  .teaser-grid .row {
    margin-left: 0;
    margin-right: 0;
  }

  .teaser-grid .container,
.teaser-grid .row > .col,
.teaser-grid .row > [class*=col-] {
    padding-right: 0;
    padding-left: 0;
  }
}
.product-teaser-grid {
  border-bottom: 1px solid #000000;
  border-top: 1px solid #000000;
}

.product-teaser .teaser-content {
  padding-left: 20%;
  padding-right: 20%;
  padding-top: 10%;
  padding-bottom: 10%;
}

.product-teaser {
  width: 100%;
  height: 100%;
  position: relative;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border: 1px solid #000000;
}
@media (max-width: 767.98px) {
  .product-teaser {
    border-left: none;
    border-right: none;
  }
}
.product-teaser::before {
  content: "";
  display: block;
  padding-bottom: 100%;
}
.product-teaser .btn-primary {
  margin-top: 0 !important;
}

.teaser-grid .teaser {
  margin-bottom: 0;
}